<template>
  <v-card class="mx-auto" :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                small
                plain
                icon
                :to="{
                  name: 'caja_medicos',
                }"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <span v-text="'Átras'" />
          </v-tooltip>
          <span class="text-h6 ml-1" v-text="$route.meta.title" />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
        </v-col>
        <v-col cols="2" class="text-right" />
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'COMPROBANTE'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-form v-on:submit.prevent ref="form_data" lazy-validation>
                <v-row dense>
                  <v-col cols="12" xs="12" md="5">
                    <v-autocomplete
                      label="Tipo"
                      v-model="receipt.type"
                      :items="types"
                      item-value="id"
                      :item-text="(i) => i.name"
                      :rules="rules.required"
                      dense
                      :disabled="data != null"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="5">
                    <v-file-input
                      label="Archivo digital*"
                      v-model="receipt.document_receipt_0"
                      accept=".xlsx,.xls"
                      :rules="rules.required"
                      show-size
                      dense
                      :disabled="data != null"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="2">
                    <v-btn
                      v-if="!data"
                      block
                      color="info"
                      dark
                      small
                      @click.prevent="receiptVerify()"
                      :loading="loading"
                    >
                      <v-icon small left> mdi-file-arrow-up-down </v-icon>
                      Cargar
                    </v-btn>
                    <v-btn
                      v-else
                      block
                      color="warning"
                      dark
                      small
                      @click.prevent="data = null"
                    >
                      <v-icon small left> mdi-file-document-refresh </v-icon>
                      Cargar nuevamente
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" v-if="data">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'LECTURA'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row dense>
                <v-col cols="12">
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Fecha</th>
                          <th>Núm. Aprobación</th>
                          <th>Tarjeta</th>
                          <th>Monto</th>
                          <th>Terminal</th>
                          <th>Folio</th>
                          <th>Valido</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, i) in data.items" :key="i">
                          <td>{{ i + 1 }}</td>
                          <td>{{ item.date_time }}</td>
                          <td>{{ item.approval_number }}</td>
                          <td>{{ "******" + item.card }}</td>
                          <td>{{ numberFormat(item.file_amount) }}</td>
                          <td>{{ item.terminal }}</td>
                          <td>{{ item.folio_full }}</td>
                          <td>
                            <v-icon
                              small
                              :color="
                                !item.rs_service_insured_bill
                                  ? ''
                                  : item.valid
                                  ? 'success'
                                  : 'warning'
                              "
                            >
                              {{ item.valid ? "mdi-check" : "mdi-close" }}
                            </v-icon>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
                <v-col cols="12" class="text-right">
                  <v-alert
                    v-if="data.valid_total == 0 && receipt.type == 1"
                    type="error"
                    dense
                  >
                    <v-row align="center">
                      <v-col class="text-center">
                        Para el comprobante Tipo TOTAL POS, al menos un registro
                        debe de coincidir para poder continuar con la
                        conciliación de pagos
                      </v-col>
                    </v-row>
                  </v-alert>
                  <v-btn
                    v-if="data.valid_total > 0"
                    color="info"
                    dark
                    small
                    @click.prevent="receiptSave()"
                    :loading="loading"
                  >
                    <v-icon small left> mdi-file-upload </v-icon>
                    Guardar
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  rules,
  msgConfirm,
  msgAlert,
  toFormData,
} from "../../control";
import ViewData from "../../components/ViewData.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    ViewData,
    FaqDlg,
  },
  data() {
    return {
      login: this.$store.getters.getLogin,
      loading: false,
      data: null,
      rules: rules(),
      receipt: {
        type: 1,
        document_receipt_0: null,
      },
      types: [
        {
          id: 1,
          name: "TOTAL POS",
        },
        {
          id: 2,
          name: "EDO. CUENTA",
        },
      ],
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    receiptVerify() {
      if (this.$refs.form_data.validate()) {
        this.data = null;
        this.loading = true;

        Axios.post(
          `${URL_API}/rss/services/insured/bills/analyze/consignment`,
          toFormData(this.receipt),
          headersToken(this.login.token)
        ).then((response) => {
          this.data = response.data.data;
          this.loading = false;
        });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    receiptSave() {
      this.$swal
        .fire(msgConfirm(`¿Confirma guardar los registros para conciliación?`))
        .then((response) => {
          if (response.isConfirmed) {
            this.loading = true;

            this.data.document_receipt_0 = this.receipt.document_receipt_0;

            Axios.post(
              `${URL_API}/rss/services/insured/bills/analyze/consignment/save`,
              toFormData(this.data),
              headersToken(this.login.token)
            ).then((response) => {
              this.$swal.fire(
                msgAlert(
                  response.data.success ? "success" : "error",
                  response.data.message
                )
              );

              if (response.data.success) {
                this.$router.push({
                  name: "caja_medicos",
                });
              } else {
                console.log(response.data.message);
              }

              this.loading = false;
            });
          }
        });
    },
    numberFormat(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });
  },
};
</script>